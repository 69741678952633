<template>
  <section class="border-2 border-gray-200 p-2 print:!mt-0">
    <div class="bg-gray-50 -m-2 p-2">
      <div class="flex justify-between space-x-4">
        <div class="flex space-x-1">
          <span>Für:</span>
          <h3 class="inline font-bold">
            {{ patient.firstname }} {{ patient.prefix }} {{ patient.suffix }} {{ patient.lastname }}
          </h3>

          <span v-if="patient.is_fake">
            ({{ record.age ? record.age + " Jahre, " : "" }}{{ patient.gender ? patientGender[patient.gender] : "" }})
          </span>
          <span v-else>
            ({{ patient.birthdate ? "Geb. " + patient.birthdate_formatted + ", " : ""
            }}{{ record.age ? record.age + " Jahre, " : "" }}{{ patient.gender ? patientGender[patient.gender] : "" }})
          </span>
        </div>

        <div
          class="text-sm text-right print:hidden"
          @mouseover="highlightSection = true"
          @mouseout="highlightSection = false"
        >
          <div>Patientenangaben übernehmen in PDF</div>

          <div class="flex space-x-4 justify-end">
            <component-checkbox v-model="printDoc">an Arzt</component-checkbox>
            <component-checkbox v-model="printPatient">an Patient</component-checkbox>
          </div>
        </div>
      </div>

      <p
        class="text-gray-500 text-xs"
        :class="{
          '[.print-doc_&]:print:hidden': !printDoc,
          '[.print-patient_&]:print:hidden': !printPatient,
        }"
      >
        Folgende Eingabedaten wurden der Apotheke (vom Patienten) mitgeteilt:
      </p>
    </div>

    <dl
      class="mt-4 divide-y divide-gray-200 break-before-avoid-page"
      :class="{
        '[.print-doc_&]:print:hidden': !printDoc,
        '[.print-patient_&]:print:hidden': !printPatient,
        'bg-yellow-50': highlightSection,
      }"
    >
      <pages-record-result-patient-data-additional-info :record="record" class="print:break-inside-avoid-page" />

      <pages-record-result-patient-data-symptoms :record="record" class="print:break-inside-avoid-page" />

      <pages-record-result-patient-data-diseases :record="record" class="print:break-inside-avoid-page" />

      <pages-record-result-patient-data-laborvalues :record="record" class="print:break-inside-avoid-page" />

      <pages-record-result-patient-data-patient-attributes :patient="patient" class="print:break-inside-avoid-page" />
    </dl>
  </section>
</template>

<script>
  import {ref} from "vue";

  import {patientGender} from "@pages/Patients/Enums/Enums.js";

  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  import PagesRecordResultPatientDataAdditionalInfo from "@pages/Records/Components/Result/PatientData/AdditionalInfo.vue";
  import PagesRecordResultPatientDataDiseases from "@pages/Records/Components/Result/PatientData/Diseases.vue";
  import PagesRecordResultPatientDataLaborvalues from "@pages/Records/Components/Result/PatientData/Laborvalues.vue";
  import PagesRecordResultPatientDataPatientAttributes from "@pages/Records/Components/Result/PatientData/PatientAttributes.vue";
  import PagesRecordResultPatientDataSymptoms from "@pages/Records/Components/Result/PatientData/Symptoms.vue";

  export default {
    name: "PagesRecordResultPatientData",

    components: {
      PagesRecordResultPatientDataPatientAttributes,
      ComponentCheckbox,
      PagesRecordResultPatientDataLaborvalues,
      PagesRecordResultPatientDataDiseases,
      PagesRecordResultPatientDataSymptoms,
      PagesRecordResultPatientDataAdditionalInfo,
    },

    props: {
      /** @var Object patient is an already decrypted (!) patient */
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
    },

    setup() {
      const printDoc = ref(true);
      const printPatient = ref(true);
      const highlightSection = ref(false);

      return {highlightSection, printDoc, printPatient, patientGender};
    },
  };
</script>
