import NumberHelper from "@utils/Helpers/NumberHelper.js";
import _ from "lodash";

export function getDeviationFromValue(value, low, high) {
  let deviation = "normal";
  let currentValue;
  let currentLow = low;
  let currentHigh = high;

  if (!currentLow && !currentHigh) {
    return deviation;
  }

  if (!low) {
    currentLow = 0;
    currentHigh = currentHigh.substr(2);
  } else if (!high) {
    currentHigh = Number.MAX_SAFE_INTEGER.toString();
    if (currentLow.startsWith(">")) {
      currentLow = currentLow.substring(1);
    } else {
      currentLow = currentLow.substr(2);
    }
  }

  if (!value) {
    currentValue = "";
  } else if (NumberHelper.isInteger(value) || NumberHelper.isFloat(value)) {
    currentValue = NumberHelper.formatStringAsFloat(value);
    currentHigh = NumberHelper.formatStringAsFloat(currentHigh);
    currentLow = NumberHelper.formatStringAsFloat(currentLow);
  } else if (NumberHelper.isStringFraction(value)) {
    currentValue = NumberHelper.formatStringFractionAsDecimal(value);
    currentHigh = NumberHelper.formatStringFractionAsDecimal(currentHigh);
    currentLow = NumberHelper.formatStringFractionAsDecimal(currentLow);
  }

  if (currentValue !== "" && currentValue >= currentHigh) {
    deviation = "raised";
  } else if (currentValue !== "" && currentValue < currentLow) {
    deviation = "lowered";
  }

  return deviation;
}

export function getDeviationFromBloodpressureValue(value, low, high) {
  let deviation = "normal";
  let currentValue;
  let currentLow = low.split("/");
  let currentHigh = high.split("/");

  if (currentLow?.length !== 2 || currentHigh?.length !== 2) {
    return deviation;
  }

  currentValue = value?.split("/");

  if (!currentValue || currentValue.length < 2) {
    return deviation;
  }

  const currentValueSystolic = NumberHelper.formatStringAsFloat(currentValue[0]);
  const currentValueDiastolic = NumberHelper.formatStringAsFloat(currentValue[currentValue.length - 1]);

  const currentLowSystolic = NumberHelper.formatStringAsFloat(currentLow[0]);
  const currentLowDiastolic = NumberHelper.formatStringAsFloat(currentLow[1]);
  const currentHighSystolic = NumberHelper.formatStringAsFloat(currentHigh[0]);
  const currentHighDiastolic = NumberHelper.formatStringAsFloat(currentHigh[1]);

  if (currentValueSystolic > currentHighSystolic || currentValueDiastolic > currentHighDiastolic) {
    deviation = "raised";
  } else if (currentValueSystolic < currentLowSystolic || currentValueDiastolic < currentLowDiastolic) {
    deviation = "lowered";
  }

  return deviation;
}

export function getReferenceSymptomByDeviation(deviation, laborvalue) {
  if (!laborvalue.core.referenceSymptoms) {
    return null;
  }

  const index = _.findIndex(laborvalue.core.referenceSymptoms, (item) => {
    return item.deviation === deviation;
  });

  if (index < 0) {
    return null;
  }

  return {
    symptom_abdata_key_miv: laborvalue.core.referenceSymptoms[index].symptom.Key_MIV,
    symptom_name: laborvalue.core.referenceSymptoms[index].symptom.Name,
  };
}

export function getReferenceValue(laborvalue, patientGender) {
  const list = laborvalue.core.referenceValueList;
  let gender = patientGender;

  if (gender === null || gender.length === 0 || gender === "diverse") {
    gender = "female";
  }

  if (!laborvalue.mc_unit_id || typeof list === "undefined" || list.length === 0) {
    return null;
  }

  let refId = _.findIndex(list, (item) => {
    return item.gender === gender && item.unit_id === laborvalue.mc_unit_id;
  });

  if (refId < 0) {
    return null;
  }

  return list[refId];
}

export function getReferenceText(referenceValue) {
  let reference = null;

  if (referenceValue.low && referenceValue.high) {
    reference = referenceValue.low + " - " + referenceValue.high;
  } else if (referenceValue.low) {
    reference = referenceValue.low;
  } else {
    reference = referenceValue.high ?? null;
  }

  if (reference) {
    return "Referenz: " + reference;
  }

  return null;
}
