<template>
  <div class="inline-block w-full">
    <template v-if="label">
      <label
        :class="{
          'text-mcred': isInvalid,
          'text-gray-500': !isInvalid && !large,
          'text-sm': !large,
        }"
        class="duration-300 -z-1 origin-0 truncate"
        :for="fieldId"
        :data-test="testId + '-select-label'"
      >
        {{ label }}
      </label>
    </template>

    <div class="flex relative" :class="background">
      <div v-if="hasIconSlot" class="absolute ml-1 pt-2">
        <slot name="icon" />
      </div>

      <select
        :id="fieldId"
        v-model="selected"
        class="bg-transparent h-9 text-left w-full border-0 border-b-1 border-gray-300 focus:ring-0 focus:border-mcred items-center text-ellipsis pr-6"
        :class="{
          'px-2': !hasIconSlot,
          'pl-8 pr-2': hasIconSlot,
        }"
        :disabled="disabled"
        :data-test="testId + '-select'"
        @change="onChange"
      >
        <option
          v-if="showPlaceholderOption"
          :disabled="!nullable"
          :value="null"
          :data-test="testId + '-select-placeholder'"
        >
          {{ placeholderText }}
        </option>

        <template v-if="options || options.length">
          <option
            v-for="(option, index) in optionList"
            :key="index"
            :value="option[keyValue]"
            :data-test="testId + '-select-option-' + index"
          >
            {{ option[keyName] }}
          </option>
        </template>

        <slot v-if="!options || !options.length" name="default" />
      </select>
    </div>

    <div v-if="validation" class="mt-1.5 text-xs text-mcred" :data-test="testId + '-select-error-message'">
      {{ validation }}
    </div>

    <div
      v-if="helperText && !isInvalid"
      class="mt-1.5 text-xs text-gray-500"
      :data-test="testId + '-select-helper-text'"
    >
      {{ helperText }}
    </div>
  </div>
</template>

<script>
  import {computed, onBeforeMount, ref, watch} from "vue";

  export default {
    name: "ComponentSelect",

    props: {
      options: {
        type: [Array, Object],
        default: () => {
          return [];
        },
      },
      label: {
        type: String,
        default: "",
      },
      validation: {
        type: String,
        default: null,
      },
      helperText: {
        type: String,
        default: null,
      },
      background: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      showPlaceholderOption: {
        type: Boolean,
        default: true,
      },
      placeholderText: {
        type: String,
        default: "Bitte auswählen",
      },
      nullable: {
        type: Boolean,
        default: false,
      },
      keyName: {
        type: String,
        default: "label",
      },
      keyValue: {
        type: String,
        default: "value",
      },
      modelValue: {
        type: [String, Number, Object],
        default: "",
      },
      returnType: {
        type: String,
        default: "value",
      },
      large: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["change", "update:modelValue"],

    setup(props, {emit, slots}) {
      const fieldId = ref(crypto.randomUUID());
      const selected = ref(null);

      const hasIconSlot = computed(() => !!slots.icon);

      const isInvalid = computed(() => props.validation);

      const optionList = computed(() => {
        if (!props.options) {
          return [];
        }

        if (Array.isArray(props.options)) {
          return props.options;
        }

        let options = [];
        Object.keys(props.options).forEach((key) => {
          if (props.options[key] === "") {
            options.unshift(props.options[key]);
          } else {
            options.push({[props.keyValue]: key, [props.keyName]: props.options[key]});
          }
        });

        return options;
      });

      watch(
        () => props.modelValue,
        (newValue) => {
          handleUpdateValue(newValue);
        },
      );

      onBeforeMount(() => {
        handleUpdateValue(props.modelValue);
      });

      const handleUpdateValue = (newValue) => {
        if (newValue || !isNaN(parseFloat(newValue))) {
          if (newValue instanceof Object) {
            selected.value = newValue[props.keyValue];
          } else {
            selected.value = newValue;
          }
        } else {
          selected.value = "";
        }
      };

      const onChange = (event) => {
        const value = selected.value ?? null;
        const text = event.target.options[event.target.options.selectedIndex].text;

        if (props.returnType === "object") {
          if (Array.isArray(props.options)) {
            const payload = props.options.find((option) => option[props.keyName] === text);
            emit("update:modelValue", payload);
            emit("change", payload);
          } else {
            emit("update:modelValue", {[props.keyName]: text, [props.keyValue]: value});
            emit("change", {[props.keyName]: text, [props.keyValue]: value});
          }
        } else {
          emit("update:modelValue", value);
          emit("change", value);
        }
      };

      return {
        /** const */
        fieldId,
        selected,

        /** computed */
        isInvalid,
        optionList,
        hasIconSlot,

        /** function */
        onChange,
      };
    },
  };
</script>
