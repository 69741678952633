<template>
  <header class="flex items-center justify-between space-x-2 py-2 px-4">
    <div>
      <inertia-link :href="$route('login')" :data-test="testId + '-guest-header-medicheck-logo-inertia-link'">
        <Logo />
      </inertia-link>
    </div>

    <div class="order-3 text-right">
      <div class="hidden sm:block text-xs text-gray-500">MediCheck Service und Vertrieb</div>

      <a href="https://www.pharma4u.de" target="_blank" :data-test="testId + '-guest-header-pharma4u-logo-link'">
        <img class="sm:hidden" src="/resources/assets/images/pharma4u-icon-rgb.png" width="40" alt="" />
        <img class="hidden sm:inline-block" src="/resources/assets/images/pharma4u-logo-rgb.png" width="142" alt="" />
      </a>
    </div>
  </header>
</template>

<script>
  import {Link as InertiaLink} from "@inertiajs/vue3";
  import Logo from "@components/Layout/Components/Logo.vue";

  export default {
    name: "LayoutGuestHeader",

    components: {Logo, InertiaLink},
  };
</script>
