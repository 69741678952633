import {dosageUnits} from "@enums/medicationEnums.js";

/**
 * @param {Object} dosageForm
 * @return {Object}
 */
export function getCurrentDosageUnit(dosageForm) {
  return {
    unit: dosageForm.selected_unit,
    keyDoe: dosageForm.selected_unit_key_doe,
  };
}

/**
 * @param {Object} coreData
 * @return {Object}
 */
export function getDosageUnitList(coreData) {
  return typeof coreData.unitList !== "undefined" && coreData.unitList.length > 0 ? coreData.unitList : dosageUnits;
}

/**
 * @param {Object} dosageForm
 * @param {string} type
 * @param {string|number|null} newValues
 * @param {Function} callbacks
 */
export function updateDosage(dosageForm, type, newValues, callbacks) {
  const asFraction = type + "_as_fraction";

  dosageForm[type] = newValues.value;
  dosageForm[asFraction] = newValues.as_fraction;

  if (typeof callbacks === "function") {
    callbacks();
  }
}

/**
 * @param {Object} dosageForm
 * @param {string|number} newKeyDoe
 * @param {string|number} newUnit
 * @param {Function} callbacks
 */
export function updateDosageUnit(dosageForm, newKeyDoe, newUnit, callbacks) {
  dosageForm.selected_unit_key_doe = newKeyDoe;
  dosageForm.selected_unit = newUnit;

  if (typeof callbacks === "function") {
    callbacks();
  }
}
