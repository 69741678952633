<template>
  <ul>
    <li v-for="(item, index) in dataList" :key="index" class="flex space-x-4 items-baseline">
      <component-dosage-input
        :id="'specialDosage' + dataHashId + index"
        :name="'specialDosage' + dataHashId + index"
        :value="item.value"
        :as-fraction="item.value_as_fraction"
        @input="handleInput($event, index)"
        @is-invalid="$emit('isInvalid', $event)"
      />

      <div class="pt-2 text-sm truncate">
        {{ unit }}
      </div>

      <template v-if="type === 'specialMonth'">
        <component-select
          v-model="item.month"
          class="!w-28"
          select-box-width="w-28"
          :options="monthNumber"
          @change="handleMonth($event, index)"
        />
      </template>

      <template v-if="type === 'multipleWeek' || type === 'specialMonth'">
        <component-select
          v-model="item.weekday"
          class="!w-28"
          :options="daysNumber"
          @change="handleWeekDay($event, index)"
        />
      </template>

      <template v-if="!['multipleHours', 'specialWeeks', 'onceAtMonth', 'specialMonth'].includes(type)">
        <component-time-select :value="item.time" @selected-time="handleTimeInput($event, index)" />
      </template>
    </li>

    <component-switch
      v-model="valueForAllTimes"
      class="mt-6"
      color="accent"
      label="Für alle Zeitpunkte übernehmen"
      @input="setValueForAllTimes"
    />
  </ul>
</template>

<script>
  import {onBeforeMount, ref, watch} from "vue";
  import _ from "lodash";

  import ComponentDosageInput from "@components/Inputs/DosageInput.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentSwitch from "@components/Switch.vue";
  import ComponentTimeSelect from "@components/TimeSelect.vue";

  import {daysNumber, monthNumber} from "@enums/medicationEnums.js";

  export default {
    name: "MedicationComponentDetailDialogMultipleDosageInput",

    components: {ComponentSwitch, ComponentSelect, ComponentTimeSelect, ComponentDosageInput},

    props: {
      dataHashId: {
        type: String,
        required: true,
        default: "",
      },
      dosageForm: {
        type: Object,
        required: true,
        default: () => {},
      },
      type: {
        type: String,
        required: true,
        default: "",
      },
      intervalValue: {
        type: [String, Number],
        required: true,
        default: 0,
      },
    },

    emits: ["isInvalid", "change", "isValid"],

    setup(props, {emit}) {
      const id = ref(null);
      const unit = ref(null);
      const dataList = ref([]);
      const valueForAllTimes = ref(false);

      const lastEnteredValue = ref({
        dosageValue: 0,
        dosageValueAsFraction: false,
      });

      watch(
        () => props.intervalValue,
        () => {
          dataList.value.splice(0, dataList.value.length);
          valueForAllTimes.value = false;

          updateDataList();
        },
      );

      onBeforeMount(() => {
        id.value = `special-dosage -${Math.floor(Math.random() * 1000000)}`.replaceAll(" ", "");
        unit.value = props.dosageForm.selected_unit ?? "";

        updateDataList();
      });

      const updateDataList = () => {
        for (let i = 0; i <= props.intervalValue - 1; i++) {
          let dataListObject = _.cloneDeep(props.dosageForm?.dosagealternative?.dosagealternativedatas?.[i]) ?? {
            dayofmonth: null,
            month: null,
            time: null,
            value: 0,
            value_as_fraction: false,
            weekday: null,
          };

          dataList.value.push(dataListObject);
        }
      };

      const handleInput = ({value, as_fraction, id}, index) => {
        dataList.value[index].value = value;
        dataList.value[index].value_as_fraction = as_fraction;

        lastEnteredValue.value.dosageValue = value;
        lastEnteredValue.value.dosageValueAsFraction = as_fraction;

        valueForAllTimes.value =
          [
            ...new Set(
              dataList.value.map((n) => {
                return n.value.toString() + n.value_as_fraction;
              }),
            ),
          ].length === 1;

        emit("isValid", id);
        emit("change", {newData: dataList.value});
      };

      const handleWeekDay = (newWeekDay, index) => {
        dataList.value[index].weekday = newWeekDay;

        emit("change", {newData: dataList.value});
      };

      const handleMonth = (newMonth, index) => {
        dataList.value[index].month = newMonth;

        emit("change", {newData: dataList.value});
      };

      const handleTimeInput = ({time}, index) => {
        dataList.value[index].time = time;

        emit("change", {newData: dataList.value});
      };

      const setValueForAllTimes = (newValue) => {
        valueForAllTimes.value = newValue;

        if (valueForAllTimes.value) {
          dataList.value.map((item) => {
            item.value = lastEnteredValue.value.dosageValue;
            item.value_as_fraction = lastEnteredValue.value.dosageValueAsFraction;
          });
        }
      };

      return {
        /** enum */
        daysNumber,
        monthNumber,

        /** const */
        id,
        unit,
        dataList,
        valueForAllTimes,

        /** function */
        handleInput,
        handleWeekDay,
        handleMonth,
        handleTimeInput,
        setValueForAllTimes,
      };
    },
  };
</script>
