<template>
  <dashboard-widget
    :hide-actions="!showFullForm"
    :class="{'min-h-48': !showFullForm, '!shadow-2xl !h-auto': showFullForm}"
    :test-id="testId + '-quick-start-exclusive'"
  >
    <template #title>
      <div class="flex justify-between items-start">
        <a
          class="group hover:no-underline flex items-center space-x-3"
          :data-test="testId + '-quick-start-exclusive-widget-show-full-form-button'"
          @click.prevent="showFullForm = true"
        >
          <component-icon class="p-2 bg-mcgreen group-hover:bg-mcgreen-450 rounded-full text-white">add</component-icon>
          <span class="text-gray-700 group-hover:text-gray-900">Neue Analyse</span>
        </a>

        <button
          v-if="showFullForm"
          :data-test="testId + '-quick-start-exclusive-widget-close-full-form-button'"
          @click="cancel()"
        >
          &times;
        </button>
      </div>
    </template>

    <template #default>
      <div v-if="form.processing" class="text-center">
        <component-spinner class="size-12 my-8" :test-id="testId + '-quick-start-exclusive-widget'" />
      </div>

      <div v-else>
        <form
          class="grid grid-cols-2 gap-4"
          data-test="dashboard-newAnalysisWidget-form"
          :test-id="testId + '-quick-start-exclusive-widget-form'"
          @submit.prevent="startWithNewPatient()"
        >
          <p v-if="!showFullForm" class="col-span-2 text-lg">Starten Sie eine neue Analyse manuell</p>

          <component-autocomplete
            ref="refLastnameAutocomplete"
            v-model="autocompleteInput"
            class="col-span-2"
            :label="showFullForm ? 'Nachname*' : ''"
            :fetch-method="autocompleteLastname"
            key-name="lastname"
            :clearable="!showFullForm"
            :placeholder="showFullForm ? '' : 'Nachname suchen oder neu eingeben'"
            :min-search-term-length="2"
            data-test="dashboard-newAnalysisWidget-autocomplete"
            :test-id="testId + '-quick-start-exclusive-widget-form-lastname'"
            @selected="startWithExistingPatient"
          >
            <template #autocomplete-item="{item}">
              <div class="min-w-56 min-h-10 flex space-x-2 items-center justify-between">
                <div>
                  <p v-if="item.suffix || item.prefix" class="text-sm text-gray-500 leading-tight">
                    {{ item.suffix }} {{ item.prefix }}
                  </p>

                  <div class="font-semibold">{{ item.lastname }} {{ item.firstname }}</div>

                  <p class="text-sm text-gray-500 leading-tight">
                    {{ [item.birthdate, item.insurancenumber].filter((i) => i !== null).join(", ") }}
                  </p>
                </div>

                <div>
                  <component-tooltip
                    tooltip="Neue Medikationsanalyse starten"
                    :test-id="testId + '-quick-start-exclusive-widget-form-lastname'"
                  >
                    <component-icon
                      data-test="dashboard-newAnalysisWidget-autocomplete-dropdown-createRecord"
                      class="p-1 bg-mcgreen rounded-full text-white"
                    >
                      add
                    </component-icon>
                  </component-tooltip>
                </div>
              </div>
            </template>

            <template #autocomplete-prepend>
              <div v-if="!showFullForm" class="p-3 text-sm text-gray-500 flex items-center space-x-3">
                <a
                  href="#"
                  class="font-semibold text-base"
                  :test-id="testId + '-quick-start-exclusive-widget-form-autocomplete-dropdown-create-patient-button'"
                  @click.prevent="createNewPatient()"
                >
                  als neuen Patient anlegen
                </a>
              </div>
            </template>
          </component-autocomplete>

          <component-input
            v-if="showFullForm"
            v-model="form.firstname"
            class="col-span-2"
            label="Vorname*"
            :hide-clear="true"
            :encrypted="true"
            :validation="form.errors.firstname"
            :test-id="testId + '-quick-start-exclusive-widget-form-firstname'"
            @decrypted.once="clip('firstname', $event)"
            @keyup="clip('firstname', $event.target.value)"
          />

          <component-birthdate
            v-if="showFullForm"
            v-model="form.birthdate"
            class="col-span-1"
            label="Geburtsdatum*"
            :validation="form.errors.birthdate"
            :test-id="testId + '-quick-start-exclusive-widget-form-birthdate'"
            @decrypted.once="clip('birthdate', $event)"
            @blur="clip('birthdate', $event.target.value)"
          />

          <component-select
            v-if="showFullForm"
            v-model="form.gender"
            class="col-span-1"
            label="Geschlecht*"
            :validation="form.errors.gender"
            :options="patientGender"
            :test-id="testId + '-quick-start-exclusive-widget-form-gender'"
          />

          <component-input
            v-if="showFullForm"
            v-model="form.insurancenumber"
            class="col-span-1"
            label="Versichertennummer"
            :hide-clear="true"
            :encrypted="true"
            :validation="form.errors.insurancenumber"
            :test-id="testId + '-quick-start-exclusive-widget-form-insurance-number'"
            @decrypted.once="clip('insurancenumber', $event)"
            @keyup="clip('insurancenumber', $event.target.value)"
          />
        </form>

        <p
          v-if="showFullForm"
          class="mt-2 text-sm text-right"
          :data-test="testId + '-quick-start-exclusive-widget-mandatory-information'"
        >
          * Pflichtangaben
        </p>

        <component-alert
          v-if="existingPatients.length"
          class="mt-2 !-mb-4"
          type="error"
          :allow-closing="false"
          :test-id="testId + '-quick-start-exclusive-widget-patient-exists'"
        >
          <h2 class="text-xl">Eventuell existiert dieser Patient bereits?</h2>

          <p class="mt-2">
            Es
            <template v-if="existingPatients.length === 1">existiert bereits ein Patient</template>
            <template v-if="existingPatients.length > 1">existieren bereits mehrere Patienten</template>
            mit gleichen Angaben.
            <br />
            Nutzen Sie ggf. die Suche im Feld „Nachname“ und vermeiden Sie, eine Person doppelt anzulegen.
          </p>
          <p class="mt-4">Wenn Sie eine Dublette ausschließen, können Sie diesen Hinweis ignorieren.</p>
        </component-alert>
      </div>

      <component-patient-gender-diverse-dialog ref="refDiverseDialog" />
    </template>

    <template #actions>
      <component-button
        class="p4umc-accent"
        :disabled="!isValid || form.processing"
        label="Starten"
        :test-id="testId + '-quick-start-exclusive-widget-create-patient'"
        @click="startWithNewPatient()"
      />

      <component-button
        class="p4umc-flat"
        label="Abbrechen"
        :test-id="testId + '-quick-start-exclusive-widget-cancel'"
        @click="cancel()"
      />
    </template>
  </dashboard-widget>
</template>

<script>
  import {inject, reactive, ref, computed, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import {debounce} from "lodash";

  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";
  import {patientGender} from "@pages/Patients/Enums/Enums.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentAutocomplete from "@components/Autocomplete/Autocomplete.vue";
  import ComponentBirthdate from "@components/Inputs/Birthdate.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  import DashboardWidget from "./Widget.vue";
  import ComponentPatientGenderDiverseDialog from "@components/Dialogs/PatientGenderDiverseDialog.vue";

  export default {
    name: "DashboardQuickStartExclusiveWidget",

    components: {
      ComponentPatientGenderDiverseDialog,
      ComponentAlert,
      ComponentAutocomplete,
      ComponentBirthdate,
      ComponentButton,
      ComponentIcon,
      ComponentInput,
      ComponentSelect,
      ComponentSpinner,
      ComponentTooltip,
      DashboardWidget,
    },

    setup() {
      let cancelToken;

      const axios = inject("$axios");
      const privacy = inject("$privacy");

      const refLastnameAutocomplete = ref(null);
      const refDiverseDialog = ref(null);

      const showFullForm = ref(false);
      const autocompleteInput = ref(null);
      const existingPatients = ref([]);

      const form = useForm({
        patient_id: null,
        lastname: null,
        firstname: null,
        birthdate: null,
        insurancenumber: null,
        gender: null,
      });

      // separate clipped values from form, as they would
      // always falsely trigger form.isDirty state
      const clippedValues = reactive({
        firstname_clipped: null,
        lastname_clipped: null,
        birthdate_clipped: null,
        insurancenumber_clipped: null,
      });

      const isValid = computed(() => {
        return form.firstname !== null && form.lastname !== null && form.birthdate !== null && form.gender !== null;
      });

      watch(autocompleteInput, () => {
        // autocompleted lastname is not clipped yet...
        clip("lastname", autocompleteInput.value);

        // autocompleted lastname is not encrypted yet...
        privacy.encryptValue(autocompleteInput.value).then((encryptedLastname) => {
          form.lastname = encryptedLastname;
        });
      });

      watch(
        () => form.gender,
        () => {
          if (form.gender === "diverse") {
            refDiverseDialog.value.open();
          }
          searchExistingPatients();
        },
      );

      watch(
        clippedValues,
        debounce(() => {
          searchExistingPatients();
        }, 500),
      );

      function clip(field, value) {
        switch (field) {
          case "birthdate":
            if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
              // YYYY-MM-DD
              clippedValues["birthdate_clipped"] = value.split("-")[0] ?? null;
            } else if (/^\d{1,2}.\d{1,2}.\d{4}$/.test(value)) {
              clippedValues["birthdate_clipped"] = value.split(".")[2] ?? null;
            } else {
              clippedValues["birthdate_clipped"] = null;
            }
            break;
          case "firstname":
          case "suffix":
          case "prefix":
          case "lastname":
          case "insurancenumber":
            clippedValues[field + "_clipped"] = (value ?? "").substring(0, 3) || null;
            break;
        }
      }

      /**
       * Search for existing patients when typing in "lastname" autocomplete field
       * @param searchTerm
       * @returns {Promise<unknown>}
       */
      function autocompleteLastname(searchTerm) {
        let controller = new AbortController();

        return new Promise((resolve) => {
          axios
            .post(
              route("api.patients.search"),
              {
                lastname_clipped: searchTerm,
              },
              {
                signal: controller.signal,
              },
            )
            .then((response) => {
              Promise.all(response.data.data.map(async (patient) => await privacy.decryptPatient(patient))).then(
                (foundPatients) => {
                  if (foundPatients.length > 0) {
                    let searchResult = filterExistingPatients(
                      // filter null values from form.data()
                      // Object.fromEntries(Object.entries(form.data()).filter(([key, value]) => value !== null)),
                      {
                        lastname: autocompleteInput.value,
                      },
                      // filter in foundPatients
                      foundPatients,
                      // compare these fields
                      ["lastname"],
                      // loose comparison
                      true,
                    ).map((patient) => {
                      let d = new Date(patient.birthdate ?? null);
                      patient.birthdate = isNaN(d.getTime())
                        ? null
                        : d.toLocaleDateString("de-DE", {dateStyle: "medium"});
                      return patient;
                    });
                    resolve(searchResult);
                  } else {
                    resolve([]);
                  }
                },
              );
            });
        });
      }

      function searchExistingPatients() {
        // early exit if required fields are not set
        if (
          !clippedValues.firstname_clipped ||
          !clippedValues.lastname_clipped ||
          !clippedValues.birthdate_clipped ||
          !form.data().gender
        ) {
          return;
        }

        if (typeof cancelToken !== "undefined") {
          cancelToken.abort();
        }

        let controller = new AbortController();

        axios
          .post(route("api.patients.search"), clippedValues, {
            signal: controller.signal,
          })
          .then((response) => {
            Promise.all(response.data.data.map(async (patient) => await privacy.decryptPatient(patient))).then(
              (foundPatients) => {
                if (foundPatients.length > 0) {
                  privacy.decryptPatient(form.data()).then((currentPatient) => {
                    let compareAgainst = ["firstname", "lastname", "gender", "birthdate"];
                    if (currentPatient.insurancenumber !== null) {
                      compareAgainst.push("insurancenumber");
                    }
                    existingPatients.value = filterExistingPatients(currentPatient, foundPatients, compareAgainst).map(
                      (patient) => {
                        let d = new Date(patient.birthdate ?? null);
                        patient.birthdate = isNaN(d.getTime())
                          ? null
                          : d.toLocaleDateString("de-DE", {dateStyle: "medium"});
                        return patient;
                      },
                    );
                  });
                }
              },
            );
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              // expected to happen
            }
          });

        cancelToken = controller;
      }

      function startWithExistingPatient(selectedPatient) {
        refLastnameAutocomplete.value.reset();

        form
          // reject everything but "patient_id"
          .transform((data) => ({
            patient_id: selectedPatient.id,
          }))
          .post(route("dashboard.quickstart"));
      }

      function startWithNewPatient() {
        if (!isValid.value) {
          return false;
        }

        form
          .transform((data) => ({
            ...data,
            ...clippedValues,
          }))
          .post(route("dashboard.quickstart"));
      }

      function createNewPatient() {
        existingPatients.value = [];
        form.patient_id = null;
        form.firstname = null;
        form.birthdate = null;
        form.gender = null;
        form.insurancenumber = null;
        refLastnameAutocomplete.value.onClickOutside();
        showFullForm.value = true;
      }

      function cancel() {
        createNewPatient();
        refLastnameAutocomplete.value.reset();
        showFullForm.value = false;
        autocompleteInput.value = null;
      }

      return {
        /** enum */
        patientGender,

        /** ref */
        refLastnameAutocomplete,
        refDiverseDialog,

        /** const */
        autocompleteInput,
        form,
        showFullForm,
        existingPatients,

        /** computed */
        isValid,

        /** function */
        clip,
        autocompleteLastname,
        startWithExistingPatient,
        startWithNewPatient,
        createNewPatient,
        cancel,
      };
    },
  };
</script>
