<template>
  <component-dialog
    ref="refDialog"
    dialog-title="PDF erstellen / drucken"
    dialog-width="small"
    :is-loading="isLoading"
    @dialog-closed="restoreResultConfig()"
  >
    <template #content>
      <component-patient-release-confidentiality :patient="patient" />

      <div class="border-t-1 mt-4 pt-4">
        <ul class="space-y-3">
          <li>
            <label class="flex items-center space-x-2">
              <input v-model="type" type="radio" class="text-mcred" value="doc" />
              <div>PDF an Arzt</div>
            </label>
          </li>
          <li>
            <label class="flex items-center space-x-2">
              <input v-model="type" type="radio" class="text-mcred" value="patient" />
              <div>PDF an Patient</div>
            </label>
          </li>
          <li>
            <label class="flex items-center space-x-2">
              <input v-model="type" type="radio" class="text-mcred" value="archive" />
              <div class="leading-tight">
                Archiv-PDF (alle Ergebnisse)
                <br />
                <span class="text-xs">ggf. für Ihre eigene Ablage, bitte nicht an Arzt / Patienten</span>
              </div>
            </label>
          </li>
        </ul>
      </div>
    </template>

    <template #actions>
      <component-button class="p4umc-primary" label="Erstellen" :disabled="pendingAjaxRequests > 0" @click="print()">
        <component-spinner v-if="pendingAjaxRequests" class="h-4 ml-2 mr-1" />
      </component-button>
      <component-button label="Abbrechen" @click="close()" />
    </template>
  </component-dialog>
</template>

<script>
  import {inject, ref, watch} from "vue";
  import axios from "axios";

  import readDom from "@utils/Helpers/ReadDom.js";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentPatientReleaseConfidentiality from "@components/Dialogs/PatientReleaseConfidentiality/PatientReleaseConfidentiality.vue";
  import {router} from "@inertiajs/vue3";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "ComponentRecordPrintPdfDialog",

    components: {ComponentSpinner, ComponentButton, ComponentPatientReleaseConfidentiality, ComponentDialog},

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const broadcast = inject("$broadcast");
      const pendingAjaxRequests = inject("$pendingAjaxRequests");

      const refDialog = ref("null");
      const type = ref(null);
      const isLoading = ref(false);

      const simulateResultConfig = inject("simulateResultConfig");
      const restoreResultConfig = inject("restoreResultConfig");

      watch(type, (newValue) => {
        switch (newValue) {
          case "doc":
            simulateResultConfig("showCommunicationDoc", true);
            simulateResultConfig("showCommunicationPatient", false);
            break;
          case "patient":
            simulateResultConfig("showCommunicationDoc", false);
            simulateResultConfig("showCommunicationPatient", true);
            break;
          case "archive":
            simulateResultConfig("showCommunicationDoc", false);
            simulateResultConfig("showCommunicationPatient", false);
            break;
        }
      });

      function open() {
        type.value = null;
        refDialog.value.open();
      }

      function close() {
        restoreResultConfig();
        refDialog.value.close();
      }

      function print() {
        isLoading.value = true;

        // readDom() contains the rendered html without data attributes
        // which is the html string we want to print
        axios
          .post(
            route("records.print", {patient: props.patient.id, record: props.record.id}),
            {
              type: type.value,
              html: readDom(type.value),
            },
            {
              responseType: "blob", // important
            },
          )
          .then((response) => {
            const contentDisposition = response.headers["content-disposition"];
            let filename = "file.pdf"; // default filename (try to get it from header...)
            if (contentDisposition) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              let matches = filenameRegex.exec(contentDisposition);
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
              }
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // force reload to trigger update of easyCom icon
            router.reload();
            // reload list of records
            broadcast.postMessage({action: "reload.records"});
            // reload specific record
            broadcast.record.postMessage(props.record.id, {action: "reload.record"});

            isLoading.value = false;
          })
          .catch((error) => {
            console.error(error);
            isLoading.value = false;
          });
      }

      return {pendingAjaxRequests, refDialog, isLoading, open, close, restoreResultConfig, type, print};
    },
  };
</script>
