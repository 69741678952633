import {reactive} from "vue";
class OptimisticHide {
  constructor() {
    this.optimisticallyHidden = reactive([]);
  }

  hide(identifier) {
    this.optimisticallyHidden.push(identifier);
  }

  revert(identifier) {
    setTimeout(() => {
      const index = this.optimisticallyHidden.indexOf(identifier);
      if (index > -1) {
        this.optimisticallyHidden.splice(index, 1);
      }
    }, 3000);
  }

  isHidden(identifier) {
    return this.optimisticallyHidden.includes(identifier);
  }
}

const instance = new OptimisticHide();
export default instance;
