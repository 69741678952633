<template>
  <div class="flex items-center justify-between space-x-2 py-2 px-4">
    <div>
      <img src="@images/logo/mc_logo.svg" width="180" alt="MediCheck" />
    </div>
  </div>
</template>

<script>
  export default {
    name: "LayoutPatientinputHeader",
  };
</script>
