<template>
  <component-button
    class="p4umc-primary p4umc-flat"
    label="Medikationsplan erstellen / drucken"
    :icon-button="true"
    :disabled="disabled"
    @click="openMedicationPlanExport()"
  >
    <component-icon class="p4umc-primary text-xl">description</component-icon>
  </component-button>

  <component-bmp-export-dialog ref="bmpDialog" :patient="patientData" :record="recordData" />
</template>

<script>
  import {computed, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentBmpExportDialog from "@components/Dialogs/BmpExportDialog.vue";

  export default {
    name: "MedicationComponentPlanPrint",

    components: {
      ComponentButton,
      ComponentIcon,
      ComponentBmpExportDialog,
    },

    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    setup() {
      const page = usePage();

      const bmpDialog = ref(null);

      const patientData = computed(() => page.props.patient ?? {});
      const recordData = computed(() => page.props.record ?? {});

      const openMedicationPlanExport = () => {
        bmpDialog.value.open();
      };

      return {
        bmpDialog,
        patientData,
        recordData,
        openMedicationPlanExport,
      };
    },
  };
</script>
