<template>
  <component-breadcrumb class="mt-2" :links="[{label: 'Patienten'}]" />

  <section class="mt-6">
    <div class="flex flex-col-reverse space-y-4 space-y-reverse justify-between md:flex-row md:space-y-none">
      <div class="flex space-x-4">
        <component-tab-button
          :active="form.filter !== 'shared-with-me' && form.filter !== 'deleted-patients'"
          :href="$route('patients.index')"
          :test-id="$page.props.testId"
        >
          Meine Patienten
        </component-tab-button>

        <component-tab-button
          v-if="$page.props.can.view_shared_records"
          :active="form.filter === 'shared-with-me'"
          :href="$route('patients.index', {filter: 'shared-with-me'})"
          :test-id="$page.props.testId + '-shared-with-me'"
        >
          Externe Patienten
        </component-tab-button>

        <component-tab-button
          v-if="$page.props.can.delete_patients"
          :active="form.filter === 'deleted-patients'"
          :href="$route('patients.index', {filter: 'deleted-patients'})"
          :test-id="$page.props.testId + '-deleted'"
        >
          Gelöschte Patienten
        </component-tab-button>
      </div>

      <component-link-button
        v-if="$page.props.can.create_patients && form.filter === null"
        :href="$route('patients.create')"
        class="p4umc-primary self-end"
        :test-id="$page.props.testId + '-route-patient-create'"
      >
        <div class="flex space-x-2">
          <component-icon class="text-2xl">person_add</component-icon>
          <span class="uppercase font-medium">Neuer Patient</span>
        </div>
      </component-link-button>
    </div>

    <div class="p-4 bg-white shadow-sm">
      <!-- filter :: start -->
      <form :data-test="$page.props.testId + '-form'" @submit.prevent="submitFilter">
        <div class="flex justify-between items-baseline space-x-8">
          <div>
            <p v-if="form.filter === 'deleted-patients'" class="mb-2">
              Gelöschte Patienten werden automatisch nach 30 Tagen gelöscht.
            </p>

            <div>
              <component-input
                id="search-input"
                v-model="form.q.search"
                autocomplete="off"
                class="max-w-72 lg:!w-72"
                placeholder="Suche"
                :helper-text="
                  form.filter === 'shared-with-me' ? 'nach Alias' : 'nach Name, Geburtsdatum, Versichertennummer'
                "
                :test-id="$page.props.testId + '-search'"
              >
                <template #icon>
                  <component-icon class="text-xl text-gray-600">search</component-icon>
                </template>
              </component-input>
            </div>
          </div>

          <component-filter-flags
            v-if="Object.keys(availableFlags).length > 0"
            v-model="form.flags"
            :available-flags="availableFlags"
            :test-id="$page.props.testId"
          />
        </div>
      </form>
      <!-- filter :: end -->

      <!-- table listing :: start -->
      <article class="mt-4 pt-4">
        <table v-if="filteredPatients.length" class="table-auto w-full">
          <thead>
            <patients-external-patient-head
              v-if="form.filter === 'shared-with-me'"
              v-model="form.order"
              :test-id="$page.props.testId"
            />

            <patients-deleted-patient-head
              v-else-if="form.filter === 'deleted-patients'"
              v-model="form.order"
              :test-id="$page.props.testId"
            />

            <patients-patient-head v-else v-model="form.order" :test-id="$page.props.testId" />
          </thead>

          <tbody>
            <template v-if="form.processing">
              <tr>
                <td colspan="5">
                  <div class="w-full grid place-content-center min-h-32">
                    <component-spinner class="h-8 w-8" :test-id="$page.props.testId" />
                  </div>
                </td>
              </tr>
            </template>

            <template v-else-if="form.filter === 'shared-with-me'">
              <template v-for="(patient, index) in filteredPatients">
                <patients-external-patient-row
                  v-if="!isOptimisticallyHidden(patient.id)"
                  :key="patient.id"
                  :patient="patient"
                  :test-id="$page.props.testId + '-' + index"
                  @open:patient-note="openPatientNote(patient)"
                  @open:patient-todos="openPatientTodos(patient)"
                  @confirm:delete="confirmForceDelete(patient)"
                />
              </template>
            </template>

            <template v-else-if="form.filter === 'deleted-patients'">
              <template v-for="(patient, index) in filteredPatients">
                <patients-deleted-patient-row
                  v-if="!isOptimisticallyHidden(patient.id)"
                  :key="patient.id"
                  :patient="patient"
                  :test-id="$page.props.testId + '-' + index"
                  @confirm:delete="confirmForceDelete(patient)"
                  @confirm:recover="confirmRecover(patient)"
                />
              </template>
            </template>

            <template v-else>
              <template v-for="(patient, index) in filteredPatients">
                <patients-patient-row
                  v-if="!isOptimisticallyHidden(patient.id)"
                  :key="patient.id"
                  :patient="patient"
                  :test-id="$page.props.testId + '-' + index"
                  @open:patient-note="openPatientNote(patient)"
                  @open:patient-todos="openPatientTodos(patient)"
                  @confirm:delete="confirmDelete(patient)"
                />
              </template>
            </template>
          </tbody>
        </table>

        <template v-else>
          <component-empty-state v-if="form.q.search || form.flags.length" :test-id="$page.props.testId + '-search'">
            Für die aktuelle Suche / Filter wurden keine Patienten gefunden.
          </component-empty-state>

          <component-empty-state
            v-else-if="form.filter === 'shared-with-me' || form.filter === 'deleted-patients'"
            :test-id="$page.props.testId + form.filter"
          >
            <template v-if="form.filter === 'shared-with-me'">
              Es wurde kein Patient mit Ihnen geteilt, daher haben Sie keine externe Patienten.
            </template>

            <template v-else-if="form.filter === 'deleted-patients'">Sie haben keine gelöschten Patienten.</template>
          </component-empty-state>

          <component-empty-state v-else class="mr-10 max-w-[38rem]" :test-id="$page.props.testId">
            <div class="flex items-start">
              <div class="space-y-4">
                <p>
                  Legen Sie über den Button
                  <span class="font-semibold">„Neuer Patient“</span>
                  Ihren ersten Patienten an.
                </p>
              </div>

              <div class="relative -top-10 fill-blue-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xml:space="preserve"
                  viewBox="0 0 100 100"
                  class="rotate-90 size-32"
                >
                  <path
                    d="M5.78 13.88c3.89-.42 7.89.35 11.76.77l11.91 1.32c1.31.14 2.82 3.76 1.07 3.78-.29 0-.57 0-.86.01-.2 0-.4-.06-.6-.16l-10.27-1.14c-2.27-.25-4.58-.62-6.89-.79 26.97 9.44 53.72 22.63 71.31 45.91 4.78 6.33 8.75 13.24 11.63 20.64.26.68.34 1.88-.6 2.03-.93.15-1.76-1.07-2.04-1.78-10.34-26.5-34.56-44.05-59.69-55.27-6.59-2.94-13.34-5.51-20.16-7.89 1.72 1.81 3.43 3.62 5.15 5.42.55.58 1.08 1.93.52 2.65-.61.8-1.59 0-2.06-.49-2.16-2.28-4.32-4.55-6.48-6.83-1.87-1.97-3.96-3.87-4.45-6.67-.1-.57.01-1.43.75-1.51z"
                  />
                </svg>
              </div>
            </div>
          </component-empty-state>
        </template>

        <!-- Paginator -->
        <component-pagination
          v-if="!form.processing && patients.meta"
          :links="patients.meta.links"
          :test-id="$page.props.testId"
        />
      </article>
      <!-- table listing :: end -->
    </div>
  </section>

  <component-patient-note-dialog ref="refPatientNoteDialog" :test-id="$page.props.testId" />

  <component-patient-todos-dialog ref="refPatientTodosDialog" :patients="patients" :test-id="$page.props.testId" />

  <component-confirmation-dialog
    v-if="form.filter !== 'deleted-patients' && form.filter !== 'shared-with-me'"
    ref="refConfirmDeleteDialog"
    title="Patient löschen?"
    content="Die Patientenakte wird mit allen zugehörigen Medikationsanalysen in „Gelöschte Patienten“ verschoben."
    :test-id="$page.props.testId + '-soft-delete-patient'"
    @confirmed="(patient) => remove(patient)"
  />

  <component-confirmation-dialog
    v-if="form.filter === 'deleted-patients' || form.filter === 'shared-with-me'"
    ref="refConfirmForceDeleteDialog"
    title="Patient endgültig löschen?"
    content="Die Patientenakte wird mit allen enthaltenen Daten endgültig und unwiederbringlich gelöscht."
    :test-id="$page.props.testId + '-force-delete-patient'"
    @confirmed="(patient) => forceRemove(patient)"
  />

  <component-confirmation-dialog
    v-if="form.filter === 'deleted-patients'"
    ref="refConfirmRecoverDialog"
    title="Patient wiederherstellen?"
    content="Die Patientenakte wird mit allen Inhalten wiederhergestellt."
    :test-id="$page.props.testId + '-recover-patient'"
    @confirmed="(patient) => recover(patient)"
  />
</template>

<script>
  import {ref, computed, watch, inject, defineAsyncComponent, onMounted} from "vue";
  import {usePage, useForm, router} from "@inertiajs/vue3";
  import {debounce} from "lodash";

  import OptimisticHide from "@utils/Helpers/OptimisticHide";
  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentFilterFlags from "@components/Filter/FilterFlags.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentLinkButton from "@components/Buttons/LinkButton.vue";
  import ComponentPagination from "@components/Pagination/Pagination.vue";
  import ComponentPatientNoteDialog from "@components/Dialogs/PatientNoteDialog.vue";
  import ComponentPatientTodosDialog from "@components/Dialogs/PatientTodosDialog.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTabButton from "@components/Buttons/TabButton.vue";

  import PatientsPatientHead from "@pages/Patients/Components/PatientHead.vue";
  import PatientsPatientRow from "@pages/Patients/Components/PatientRow.vue";

  const PatientsExternalPatientHead = defineAsyncComponent(
    () => import("@pages/Patients/Components/ExternalPatientHead.vue"),
  );
  const PatientsExternalPatientRow = defineAsyncComponent(
    () => import("@pages/Patients/Components/ExternalPatientRow.vue"),
  );
  const PatientsDeletedPatientHead = defineAsyncComponent(
    () => import("@pages/Patients/Components/DeletedPatientHead.vue"),
  );
  const PatientsDeletedPatientRow = defineAsyncComponent(
    () => import("@pages/Patients/Components/DeletedPatientRow.vue"),
  );

  export default {
    name: "PagesPatientIndex",

    components: {
      ComponentBreadcrumb,
      ComponentConfirmationDialog,
      ComponentEmptyState,
      ComponentFilterFlags,
      ComponentIcon,
      ComponentInput,
      ComponentLinkButton,
      ComponentPagination,
      ComponentPatientNoteDialog,
      ComponentPatientTodosDialog,
      ComponentSpinner,
      ComponentTabButton,
      PatientsDeletedPatientHead,
      PatientsDeletedPatientRow,
      PatientsExternalPatientHead,
      PatientsExternalPatientRow,
      PatientsPatientHead,
      PatientsPatientRow,
    },

    props: {
      patients: {
        type: Object,
        required: true,
      },
      filters: {
        type: Object,
        required: true,
      },
      flagContacts: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },

    setup(props) {
      const privacy = inject("$privacy");
      const hasThirdParty = inject("$hasThirdParty");

      const page = usePage();

      const refConfirmDeleteDialog = ref(null);
      const refConfirmForceDeleteDialog = ref(null);
      const refConfirmRecoverDialog = ref(null);
      const refPatientNoteDialog = ref(null);
      const refPatientTodosDialog = ref(null);

      const filteredPatients = ref([]);

      let form = useForm({
        order:
          page.props?.filters?.order ||
          (page.props?.filters?.filter === "deleted-patients" ? "-deleted_at" : "-updated_at"), // default order depends on active tab
        filter: typeof page.props?.filters?.filter !== "function" ? page.props?.filters?.filter : null,
        q: {
          search: page.props?.filters?.q?.search || null,
        },
        flags: page.props?.filters?.flags || [],
      });

      onMounted(() => {
        filterPatients();
      });

      const availableFlags = computed(() => {
        if (form.filter === "shared-with-me") {
          return {
            "nursing-home": "ist Heimpatient",
            "open-todos": "enthält offene Aufgaben",
            "missing-activity": "inaktiv seit 1 Jahr",
          };
        }
        if (form.filter === "deleted-patients") {
          return {};
        }

        if (!hasThirdParty("p4u")) {
          return {
            "nursing-home": "ist Heimpatient",
            "related-contact": {
              label: "mit Kontakt",
              options: props.flagContacts,
            },
            "missing-activity": "inaktiv seit 1 Jahr",
          };
        }

        return {
          "nursing-home": "ist Heimpatient",
          "from-wawi": "aus Warenwirtschaft",
          "service-recommended": "erneute Leistungserbringung möglich",
          "related-contact": {
            label: "mit Kontakt",
            options: props.flagContacts,
          },
          "open-todos": "enthält offene Aufgaben",
          "missing-datasecurityconfirmed": "fehlende Datenschutzeinwilligung",
          "missing-releasefromconfidentialityconfirmed": "fehlende Schweigepflichtentbindung",
          "missing-activity": "inaktiv seit 1 Jahr",
        };
      });

      watch(
        () => form.q.search,
        debounce(function () {
          submitFilter();
        }, 500),
      );

      watch(
        () => form.flags,
        debounce(function () {
          submitFilter();
        }, 1000),
      );

      watch(
        () => form.order,
        debounce(function () {
          submitFilter();
        }, 500),
      );

      watch(
        () => props.patients,
        () => {
          filterPatients();
        },
      );

      function submitFilter() {
        form.get(route("patients.index"), {preserveState: true, preserveScroll: true, replace: true});
      }

      function filterPatients() {
        privacy.whenCryptReady(() => {
          let promises = Array.from(page.props.patients.data).map((patient) => {
            const keys = ["firstname", "suffix", "prefix", "lastname", "birthdate", "insurancenumber"];
            const cryptValuePromises = keys.map((key) => {
              return privacy.getCryptObject().decrypt(patient[key]);
            });

            return Promise.all(cryptValuePromises).then((decryptedValues) => {
              // merge into patient
              let zipObject = Object.fromEntries(keys.map((key, index) => [key, decryptedValues[index]]));
              if (zipObject["birthdate"]) {
                let d = new Date(zipObject["birthdate"]);
                if (isNaN(d.getTime())) {
                  zipObject["birthdate"] = "";
                } else {
                  if (patient.is_fake) {
                    zipObject["birthdate"] = "XX.XX." + d.getFullYear();
                  } else {
                    zipObject["birthdate"] = d.toLocaleDateString("de-DE", {dateStyle: "medium"});
                  }
                }
              }

              return {...patient, ...zipObject};
            });
          });

          Promise.all(promises).then((patients) => {
            const words = (form?.q?.search?.toLowerCase().replace(",", " ") || "")
              .split(" ")
              .filter((word) => word.trim() !== "");
            filteredPatients.value = words.length
              ? patients.filter((patient) => {
                  return words.every((word) => {
                    if (/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/.test(word)) {
                      // convert searchword "1.9.1990" to "01.09.1980"
                      const [day, month, year] = word.split(".");
                      word = day.padStart(2, "0") + "." + month.padStart(2, "0") + "." + year;
                    }

                    return (
                      (patient.lastname && patient.lastname.toLowerCase().includes(word.toLowerCase())) ||
                      (patient.firstname && patient.firstname.toLowerCase().includes(word.toLowerCase())) ||
                      (patient.birthdate && patient.birthdate.includes(word.toLowerCase())) ||
                      (patient.insurancenumber &&
                        patient.insurancenumber.toLowerCase().startsWith(word.toLowerCase())) ||
                      (patient.aliasname && patient.aliasname.toLowerCase().includes(word.toLowerCase()))
                    );
                  });
                })
              : patients;
          });
        });
      }

      function openPatientNote(patient) {
        refPatientNoteDialog.value.open(patient);
      }

      function openPatientTodos(patient) {
        refPatientTodosDialog.value.open(patient);
      }

      function confirmForceDelete(patient) {
        refConfirmForceDeleteDialog.value.open(patient);
      }

      function forceRemove(patient) {
        OptimisticHide.hide(patient.id);
        router.delete(route("patients.destroy", {patient: patient.id, action: "forceDelete"}), {
          preserveScroll: true,
          onFinish: () => OptimisticHide.revert(patient.id),
        });
      }

      function confirmDelete(patient) {
        refConfirmDeleteDialog.value.open(patient);
      }

      function remove(patient) {
        OptimisticHide.hide(patient.id);
        router.delete(route("patients.destroy", {patient: patient.id}), {
          preserveScroll: true,
          onFinish: () => OptimisticHide.revert(patient.id),
        });
      }

      function confirmRecover(patient) {
        refConfirmRecoverDialog.value.open(patient);
      }

      function recover(patient) {
        OptimisticHide.hide(patient.id);
        router.patch(
          route("patients.update", {patient: patient.id}),
          {action: "recover"},
          {
            preserveScroll: true,
            onFinish: () => OptimisticHide.revert(patient.id),
          },
        );
      }

      return {
        /** ref */
        refConfirmDeleteDialog,
        refConfirmForceDeleteDialog,
        refConfirmRecoverDialog,
        refPatientNoteDialog,
        refPatientTodosDialog,

        /** ref */
        form,
        filteredPatients,

        /** computed */
        availableFlags,

        /** function */
        confirmDelete,
        confirmForceDelete,
        confirmRecover,
        forceRemove,
        isOptimisticallyHidden: OptimisticHide.isHidden.bind(OptimisticHide),
        openPatientNote,
        openPatientTodos,
        recover,
        remove,
        submitFilter,
      };
    },
  };
</script>
