<script>
  import {computed, inject} from "vue";
  import {usePage} from "@inertiajs/vue3";

  export default {
    name: "Logo",
    components: {},
    setup() {
      const page = usePage();

      // do not call hasThirdParty() if we don't have the thirdPartys
      const hasThirdPartyData = computed(() => page.props.thirdPartys !== undefined);

      const hasThirdParty = inject("$hasThirdParty");

      return {
        /** computed */
        hasThirdPartyData,

        /** inject */
        hasThirdParty
      };
    }
  }
</script>

<template>
  <img class="sm:hidden p-1" src="@images/logo/mc_icon.svg" width="40" alt="MediCheck">
  <img v-if="hasThirdPartyData && hasThirdParty('p4u') && hasThirdParty('nident')" class="hidden sm:block h-12 p-1" src="@images/logo/mc_logo_connect_pro.svg" alt="MediCheck">
  <img v-else-if="hasThirdPartyData && hasThirdParty('nident')" class="hidden sm:block h-12 p-1" src="@images/logo/mc_logo_connect_core.svg" alt="MediCheck">
  <img v-else-if="hasThirdPartyData && hasThirdParty('p4u')" class="hidden sm:block h-12 p-1" src="@images/logo/mc_logo_online.svg" alt="MediCheck">
  <img v-else class="hidden sm:block h-12 p-1" src="@images/logo/mc_logo.svg" alt="MediCheck">
</template>
