export default class Bmp {
  /**
   * Validates on start or end tag
   *
   * @param {String} trimmedXml
   */
  static validateXml(trimmedXml) {
    return !(trimmedXml.match(/^<MP/) === null || trimmedXml.match(/MP>$/) === null);
  }

  /**
   * Parse XML wit DOMParser
   * @private
   *
   * @param {String} trimmedXml
   * @param {String} tagName
   * @param {String} attributeName
   *
   * @return {String}
   * @throws {Error}
   * */
  static getFromXml(trimmedXml, tagName, attributeName) {
    const parser = new DOMParser();
    // replace "&" with "&amp;" when parsing from xml string (otherwise the
    // Firefox implementation of DOMParser won't parse a company name like
    // "Apotheke & Co.")... we do not really use these values, the getFromXml()
    // method is mainly used to pre-validate a given xml, nevertheless, we
    // revert the replacement when returning the found value.
    const xmlDoc = parser.parseFromString(trimmedXml.replace(/&/g, "&amp;"), "text/xml");
    const value = xmlDoc.getElementsByTagName(tagName).item(0)?.getAttribute(attributeName);

    if (!value) {
      throw new Error();
    }

    return value.replace(/&amp;/g, "&");
  }

  /**
   * Returns the current page number from the xml
   *
   * @param {String} trimmedXml
   * @return {Number}
   */
  static getCurrentPageNumber(trimmedXml) {
    try {
      return parseInt(this.getFromXml(trimmedXml, "MP", "a"));
    } catch (e) {
      return 1;
    }
  }

  /**
   * Returns the max page number from the xml data
   *
   * @param {String} trimmedXml
   * @return {Number}
   */
  static getTotalPageNumber(trimmedXml) {
    try {
      return parseInt(this.getFromXml(trimmedXml, "MP", "z"));
    } catch (e) {
      return 1;
    }
  }

  /**
   * Returns the max page number from the xml data
   *
   * @param {String} trimmedXml
   * @return {String}
   */
  static getUniqueInstanceId(trimmedXml) {
    try {
      return this.getFromXml(trimmedXml, "MP", "U");
    } catch (e) {
      throw new Error("fehlende eindeutige Instanz-ID");
    }
  }

  /**
   * Returns the patient firstname
   *
   * @param {String} trimmedXml
   * @return {String}
   */
  static getPatientFirstname(trimmedXml) {
    try {
      return this.getFromXml(trimmedXml, "P", "g");
    } catch (e) {
      throw new Error("ungültiger oder fehlender Vorname");
    }
  }

  /**
   * Returns the patient lastname
   *
   * @param {String} trimmedXml
   * @return {String}
   */
  static getPatientLastname(trimmedXml) {
    try {
      return this.getFromXml(trimmedXml, "P", "f");
    } catch (e) {
      throw new Error("ungültiger oder fehlender Nachname");
    }
  }

  /**
   * Returns the patient gender
   *
   * @param {String} trimmedXml
   * @return {?String}
   */
  static getPatientGender(trimmedXml) {
    try {
      switch (this.getFromXml(trimmedXml, "P", "s")) {
        case "M":
          return "male";
        case "W":
          return "female";
        case "D":
          return "diverse";
        default:
          return null;
      }
    } catch (e) {
      return null;
    }
  }

  /**
   * Returns the patient birthdate (YYYY-MM-DD)
   * from all possible input formats (YYYYMMDD,
   * YYYY-MM-DD, YYYY-M-D, DD.MM.YYYY, D.M.YYYY)
   *
   * @param {String} trimmedXml
   * @return {String}
   */
  static getPatientBirthdate(trimmedXml) {
    try {
      const patientBirthdateAttribute = this.getFromXml(trimmedXml, "P", "b");

      // format: YYYYMMDD
      if (/^\d{8}$/.test(patientBirthdateAttribute)) {
        let year = patientBirthdateAttribute.substring(0, 4);
        let month = patientBirthdateAttribute.substring(4, 6);
        let day = patientBirthdateAttribute.substring(6, 8);
        return `${year}-${month}-${day}`;
      }

      // format: YYYY-MM-DD or YYYY-M-D
      let match = patientBirthdateAttribute.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
      if (match) {
        let year = match[1];
        let month = match[2].padStart(2, "0"); // ensure two digits
        let day = match[3].padStart(2, "0"); // ensure two digits
        return `${year}-${month}-${day}`;
      }

      // format: DD.MM.YYYY or D.M.YYYY
      match = patientBirthdateAttribute.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
      if (match) {
        let day = match[1].padStart(2, "0"); // ensure two digits
        let month = match[2].padStart(2, "0"); // ensure two digits
        let year = match[3];
        return `${year}-${month}-${day}`;
      }

      // None of the formats match
      throw new Error("ungültiges Format des Geburtsdatums");
    } catch (e) {
      throw new Error(e.message.length ? e.message : "fehlendes Geburtsdatum");
    }
  }

  /**
   * Returns the patient insurance number
   *
   * @param {String} trimmedXml
   * @return {?String}
   */
  static getPatientInsurancenumber(trimmedXml) {
    try {
      return this.getFromXml(trimmedXml, "P", "egk");
    } catch (e) {
      return null;
    }
  }
}
