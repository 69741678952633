<template>
  <component-dialog ref="refDialog" dialog-width="medium" :test-id="testId + '-patient-missing-data'">
    <template #title>Fehlende Pflichtangaben</template>

    <template #content>
      <component-alert>
        <h4 class="font-bold">In den Stammdaten der Patientenakte fehlen Pflichtangaben</h4>

        <p class="mt-2">Dies kann z. B. durch einen unvollständigen Datenimport verursacht worden sein.</p>
      </component-alert>

      <p class="mt-4">Bitte ergänzen Sie fehlende Angaben:</p>

      <form :data-test="testId + '-patient-missing-data-dialog-form'" @submit.prevent="save">
        <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-8">
          <component-input
            v-model="form.firstname"
            label="Vorname*"
            :hide-clear="true"
            :encrypted="true"
            :validation="form.errors.firstname"
            maxlength="45"
            :test-id="testId + '-patient-master-data-card-firstname'"
            @decrypted.once="clip('firstname', $event)"
            @keyup="clip('firstname', $event.target.value)"
          />
          <component-input
            v-model="form.lastname"
            label="Nachname*"
            :hide-clear="true"
            :encrypted="true"
            :validation="form.errors.lastname"
            maxlength="45"
            :test-id="testId + '-patient-master-data-card-lastname'"
            @decrypted.once="clip('lastname', $event)"
            @keyup="clip('lastname', $event.target.value)"
          />

          <component-birthdate
            v-model="form.birthdate"
            label="Geburtsdatum*"
            :validation="form.errors.birthdate"
            :test-id="testId + '-patient-master-data-card-birthdate'"
            @decrypted.once="clip('birthdate', $event)"
            @blur="clip('birthdate', $event.target.value)"
          />
          <component-select
            v-model="form.gender"
            label="Geschlecht*"
            :validation="form.errors.gender"
            :options="patientGender"
            :test-id="testId + '-patient-master-data-card-gender'"
          />
        </div>
        <div class="text-sm text-right">* Pflichtangaben</div>
      </form>
    </template>

    <template #actions>
      <component-button
        class="p4umc-primary"
        label="Speichern"
        :disabled="form.processing"
        :test-id="testId + '-patient-missing-data-dialog-save'"
        @click="save()"
      />

      <component-button label="Abbrechen" :test-id="testId + '-patient-missing-data-dialog-close'" @click="close()" />
    </template>
  </component-dialog>

  <component-patient-gender-diverse-dialog ref="refDiverseDialog" />
</template>

<script>
  import {inject, reactive, ref, watch} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentBirthdate from "@components/Inputs/Birthdate.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentPatientGenderDiverseDialog from "@components/Dialogs/PatientGenderDiverseDialog.vue";
  import {patientGender} from "@pages/Patients/Enums/Enums.js";

  export default {
    name: "ComponentPatientMissingDataDialog",

    components: {
      ComponentPatientGenderDiverseDialog,
      ComponentSelect,
      ComponentBirthdate,
      ComponentInput,
      ComponentAlert,
      ComponentButton,
      ComponentDialog,
    },

    expose: ["open", "close"],

    setup() {
      const page = usePage();
      const broadcast = inject("$broadcast");

      const refDialog = ref(null);
      const refDiverseDialog = ref(null);

      const currentPatient = ref(page.props.patient);

      const form = useForm({
        firstname: currentPatient.value?.firstname ?? null,
        lastname: currentPatient.value?.lastname ?? null,
        birthdate: currentPatient.value?.birthdate ?? null,
        gender: currentPatient.value?.gender ?? null,
      });

      // separate clipped values from form
      const clippedValues = reactive({
        firstname_clipped: null,
        lastname_clipped: null,
        birthdate_clipped: null,
      });

      watch(currentPatient, (patient) => {
        form.firstname = patient?.firstname;
        form.lastname = patient?.lastname;
        form.birthdate = patient?.birthdate;
        form.gender = patient?.gender;
      });

      watch(
        () => form.gender,
        () => {
          if (form.gender === "diverse") {
            refDiverseDialog.value.open();
          }
        },
      );

      function open() {
        // always read (encrypted) patient props directly from page props
        currentPatient.value = page.props.patient;
        refDialog.value.open();
      }

      function close() {
        refDialog.value.close();
      }

      function clip(field, value) {
        switch (field) {
          case "birthdate":
            if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
              // YYYY-MM-DD
              clippedValues["birthdate_clipped"] = value.split("-")[0] ?? null;
            } else if (/^\d{1,2}.\d{1,2}.\d{4}$/.test(value)) {
              clippedValues["birthdate_clipped"] = value.split(".")[2] ?? null;
            } else {
              clippedValues["birthdate_clipped"] = null;
            }
            break;
          case "firstname":
          case "suffix":
          case "prefix":
          case "lastname":
          case "insurancenumber":
            clippedValues[field + "_clipped"] = (value ?? "").substring(0, 3) || null;
            break;
        }
      }

      function save() {
        form
          .transform((data) => ({
            ...data,
            ...clippedValues,
          }))
          .patch(route("patients.update", {patient: currentPatient.value.id}), {
            preserveScroll: true,
            onSuccess: () => {
              broadcast.patient.postMessage(currentPatient.value.id, {action: "reload.patient"});
              close();
            },
          });
      }

      return {
        /** ref */
        refDialog,
        refDiverseDialog,

        /** enums */
        patientGender,

        /** const */
        currentPatient,
        form,

        /** function */
        clip,
        open,
        close,
        save,
      };
    },
  };
</script>
