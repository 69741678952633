<template>
  <component-breadcrumb class="mt-2" :links="[{label: 'Kontakte'}]" />

  <section class="mt-6">
    <div class="p-4 bg-white shadow-sm">
      <div class="flex items-center justify-between">
        <h2 class="text-2xl font-semibold">Kontakte</h2>

        <component-link-button
          label="Neuer Kontakt"
          :href="$route('contacts.create')"
          class="p4umc-primary self-end"
          :test-id="testId + '-create-new-contact'"
        />
      </div>

      <!-- filter :: start -->
      <form @submit.prevent="submitFilter">
        <div class="flex justify-between items-baseline">
          <component-input
            v-model="form.q.search"
            autocomplete="off"
            class="max-w-52"
            placeholder="Suche"
            :test-id="testId + '-search'"
          >
            <template #icon>
              <component-icon class="text-xl text-gray-600">search</component-icon>
            </template>
          </component-input>

          <component-filter-flags
            v-model="form.flags"
            :available-flags="availableFlags"
            data-test="contact"
            :test-id="testId"
          />
        </div>
      </form>
      <!-- filter :: end -->

      <!-- table listing :: start -->
      <article class="mt-4 pt-4">
        <table v-if="contacts.data.length" class="table-auto w-full">
          <thead>
            <contacts-contact-head v-model="form.order" :test-id="testId" />
          </thead>

          <tbody>
            <template v-if="form.processing">
              <tr>
                <td colspan="12">
                  <div class="w-full grid place-content-center min-h-32">
                    <component-spinner class="h-8 w-8" :test-id="testId" />
                  </div>
                </td>
              </tr>
            </template>

            <template v-else>
              <template v-for="(contact, index) in contacts.data">
                <contacts-contact-row
                  v-if="!isOptimisticallyHidden(contact.id)"
                  :key="contact.id"
                  :contact="contact"
                  :test-id="testId + '-' + index"
                  @open:delete-contact="openDialog(contact.id, 'delete')"
                />
              </template>
            </template>
          </tbody>
        </table>

        <template v-else>
          <component-empty-state v-if="form.q.search || form.flags.length" :test-id="testId">
            Für die aktuelle Suche / Filter wurden keine Kontakte gefunden.
          </component-empty-state>

          <component-empty-state v-else class="mr-10 max-w-[38rem]" :test-id="testId">
            <div class="flex items-start">
              <div class="space-y-4">
                <p>
                  Legen Sie über den Button
                  <span class="font-semibold">„Neuer Kontakt“</span>
                  Adressen von Ärzten, Pflegediensten oder Heimen an, mit denen Sie in Kontakt stehen.
                </p>
              </div>

              <div class="relative -top-10 fill-blue-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xml:space="preserve"
                  viewBox="0 0 100 100"
                  class="rotate-90 size-32"
                >
                  <path
                    d="M5.78 13.88c3.89-.42 7.89.35 11.76.77l11.91 1.32c1.31.14 2.82 3.76 1.07 3.78-.29 0-.57 0-.86.01-.2 0-.4-.06-.6-.16l-10.27-1.14c-2.27-.25-4.58-.62-6.89-.79 26.97 9.44 53.72 22.63 71.31 45.91 4.78 6.33 8.75 13.24 11.63 20.64.26.68.34 1.88-.6 2.03-.93.15-1.76-1.07-2.04-1.78-10.34-26.5-34.56-44.05-59.69-55.27-6.59-2.94-13.34-5.51-20.16-7.89 1.72 1.81 3.43 3.62 5.15 5.42.55.58 1.08 1.93.52 2.65-.61.8-1.59 0-2.06-.49-2.16-2.28-4.32-4.55-6.48-6.83-1.87-1.97-3.96-3.87-4.45-6.67-.1-.57.01-1.43.75-1.51z"
                  />
                </svg>
              </div>
            </div>
          </component-empty-state>
        </template>

        <!-- Paginator -->
        <component-pagination v-if="!form.processing && contacts.meta" :links="contacts.meta.links" :test-id="testId" />
      </article>
      <!-- table listing :: end -->
    </div>
  </section>

  <component-dialog
    ref="refDeleteDialog"
    dialog-title="Sind Sie sicher?"
    dialog-content="Die ausgewählte Zeile wird gelöscht."
    primary-label="ok"
    :test-id="testId + '-delete'"
    @primary-button-clicked="deleteRow"
    @cancel-button-clicked="closeDialog('delete')"
  />
</template>

<script>
  import {ref, watch} from "vue";
  import {router, useForm, usePage} from "@inertiajs/vue3";
  import {debounce} from "lodash";

  import {doctorProfession} from "./Enums/Enums.js";
  import OptimisticHide from "@utils/Helpers/OptimisticHide.js";

  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentFilterFlags from "@components/Filter/FilterFlags.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentLinkButton from "@components/Buttons/LinkButton.vue";
  import ComponentPagination from "@components/Pagination/Pagination.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ContactsContactHead from "@pages/Contact/Components/ContactHead.vue";
  import ContactsContactRow from "@pages/Contact/Components/ContactRow.vue";

  export default {
    name: "PagesContactsIndex",

    components: {
      ComponentBreadcrumb,
      ComponentDialog,
      ComponentEmptyState,
      ComponentFilterFlags,
      ComponentIcon,
      ComponentInput,
      ComponentLinkButton,
      ComponentPagination,
      ComponentSpinner,
      ContactsContactHead,
      ContactsContactRow,
    },

    props: {
      contacts: {
        type: Object,
        required: true,
      },
      filters: {
        type: Object,
        required: true,
      },
    },

    setup() {
      const page = usePage();

      const refDeleteDialog = ref(null);
      const selectedId = ref(null);

      const availableFlags = {
        "no-patients": "Keinem Patienten zugeordnet",
      };

      let form = useForm({
        order: page.props?.filters?.order || "lastname",
        filter: typeof page.props?.filters?.filter !== "function" ? page.props?.filters?.filter : null,
        q: {
          search: page.props?.filters?.q?.search || null,
        },
        flags: page.props?.filters?.flags || [],
      });

      watch(
        () => form.q.search,
        debounce(function () {
          submitFilter();
        }, 500),
      );

      watch(
        () => form.flags,
        debounce(function () {
          submitFilter();
        }, 1000),
      );

      watch(
        () => form.order,
        debounce(function () {
          submitFilter();
        }, 500),
      );

      function submitFilter() {
        form.get(route("contacts.index"), {preserveState: true, preserveScroll: true, replace: true});
      }

      const openDialog = (id, dialogType) => {
        selectedId.value = id;

        if (dialogType === "delete") refDeleteDialog.value.open();
      };

      const closeDialog = (dialogType) => {
        selectedId.value = null;

        if (dialogType === "delete") refDeleteDialog.value.close();
      };

      const deleteRow = () => {
        OptimisticHide.hide(selectedId.value);
        router.delete(route("contacts.destroy", {contact: selectedId.value}), {
          preserveScroll: true,
          onFinish: () => OptimisticHide.revert(selectedId.value),
        });
      };

      return {
        /** enum */
        doctorProfession,

        /** ref */
        refDeleteDialog,

        /** const */
        form,
        availableFlags,

        /** function */
        isOptimisticallyHidden: OptimisticHide.isHidden.bind(OptimisticHide),
        submitFilter,
        openDialog,
        closeDialog,
        deleteRow,
      };
    },
  };
</script>
