<template>
  <component-dialog
    ref="refDialog"
    dialog-type="information"
    dialog-title="Achtung"
    dialog-content="Mit dem Geschlecht „divers“ können im MediCheck nicht alle Prüfungen durchgeführt werden (z. B. Gegenanzeigen)."
  />
</template>

<script>
  import {ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";

  export default {
    name: "ComponentPatientGenderDiverseDialog",

    components: {
      ComponentDialog,
    },

    expose: ["open", "close"],

    setup() {
      const refDialog = ref(null);

      function open() {
        refDialog.value.open();
      }

      function close() {
        refDialog.value.close();
      }

      return {
        /** ref */
        refDialog,

        /** function */
        open,
        close,
      };
    },
  };
</script>
